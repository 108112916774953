<template>

  <el-dialog
      title="新增"
      v-model="classInfo"
      width="30%"
      center append-to-body @close='closeDialog'>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">

      <el-form-item label="用户名称" prop="user_name" :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
          ]"
      >
        <el-select   v-model="ruleForm.user_name"  placeholder="请选择" style="width:100%">
          <el-option  v-for="data in names" :key="data.repair_user" :label="data.repair_username" :value="data.repair_user">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="绩效分类" prop="father_name" :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
          ]"
      >
        <el-select   v-model="ruleForm.father_name"  placeholder="请选择" style="width:100%" @change="fenlei">
          <el-option  v-for="data in father_names" :key="data.type_id" :label="data.type_name" :value="data.type_id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="绩效名称" prop="father_name" :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
          ]"
      >
        <el-select   v-model="ruleForm.secnnames"  placeholder="请选择" style="width:100%"   :onclick="getsecn"  @change="selectChanged">
          <el-option   v-for="data in secn_names" :key="data.secn_id" :label="data.secn_name" :value="data.secn_id" :tel="data"  >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="绩效金额" prop="money"
      >
        <el-input   v-model="ruleForm.money" :readonly="true"></el-input>
      </el-form-item>
	  
	  <el-form-item label="金额倍数" prop="multiple"
	  >
	    <el-input v-model="ruleForm.multiple" type="number" min="1"></el-input>
	  </el-form-item>

    </el-form>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit('ruleForm')"><i class="iImg"></i>确定</button>
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog"><i
            class="iImg"></i>返回</button>
      </div>
    </div>
    <div class="clear"></div>

  </el-dialog>

</template>

<script>
export default {
  created () {
    this.classInfo = true;
    this.clearinfo();
    this.getplotname();
    this.getmembername();
  },

  data(){
    let self=this;
    return{
      ruleForm:{
		secnnames:"",
		father_name:""
      },
    }
  },

  methods: {
    getsecn:function(){
      var data=[];
      data=this.secn_names;
      this.secn_names=[];

      this.secn_names=data;

    },

    commit:function(ruleForm){

      this.$refs[ruleForm].validate((valid) => {

        if (valid) {
          var self = this;
          var data = {};
          data.cmd='insertOrderAndCommon';
          data.father_id=this.$parent.order_id.father_id;
          data.user_id=this.ruleForm.user_name;
          data.type_id=this.ruleForm.father_name;
          data.secn_id=this.ruleForm.secnnames;
          data.money = this.ruleForm.money * this.ruleForm.multiple;
		  data.multiple = this.ruleForm.multiple;
          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
            self.$message("修改成功");
            self.closeDialog();
          }, function(data) {
            self.$message(data.reason);
          }, self);EvaluateBean
        } else {
          console.log('error submit!!');

          return false;
        }
      });
    },
   /* 获取会员信息*/
    getmembername:function(){
      var self = this;
      var redata = {};
      redata.cmd = "queryNameByOrderId";
      redata.order_id=this.$parent.order_id.father_id;

      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.names=datas.datas.items;

      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    /*查询绩效分类*/
    fenlei:function(){
      var self = this;
      var redata = {};
      var order_type=this.$parent.order_id.order_type;
      redata.order_type=order_type;
      redata.cmd = "searchsearchLevelCommonName";
      redata.secn_id=this.ruleForm.father_name
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){


      this.secn_names=datas.datas.items;
      this.ruleForm.secnnames='';
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },

    //查询所有项目名称和ID的方法
    getplotname:function(){
      var self = this;
      var redata = {};
      redata.cmd = "searchlevelName";
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
      this.father_names=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },

    selectChanged:function(params){

      var obj = {};
      obj = this.secn_names.find(function(item) {
          return item.secn_id === params;
      });
      this.ruleForm.money=obj.money
	  this.ruleForm.multiple =1;
    },


    closeDialog: function(){
        this.classInfo = false;
      this.clearinfo();
      this.$emit('closeperformance'); //通知父组件改变。
    },
    clearinfo:function(){

    },

  },

  mounted(){

  },
  components: {

  }
}
</script>

<style scoped>

</style>