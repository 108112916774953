<template>
	
	<div class="ucLay radius">
		<div class="ucCol_24 ucRow_24">
				<div class="ucCol_9 ucRow_24">
			
				 <div class="ucCard ucCard-absolute">
					   <div class="ucCard-header">
						   <div class="fl flName">任务详情</div>
						   
						   <div class="clear"></div>
					   </div>
					   <div class="ucCard-content">
							<div>
								
								<el-form   :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-height="100px" class="ruleForm">
									
									<el-row>
									  <el-form-item label="报修用户" prop="name" >
										<el-input  :readonly="true"  v-model="ruleForm.user_name" ></el-input>
									  </el-form-item>
									  <el-form-item label="真实姓名" prop="name">
										<el-input :readonly="true"  v-model="ruleForm.real_name"></el-input>
									  </el-form-item>
									  <el-form-item label="手机号码" prop="name">
									  	<el-input  :readonly="true"  v-model="ruleForm.phone"></el-input>
									  </el-form-item>
									  <el-form-item label="绩效评定人" prop="name">
									  	 <el-input  :readonly="true"  v-model="ruleForm.evaluationName"></el-input>
									  </el-form-item>
									  </el-row>
									  
									  <el-row>
								
									  <el-form-item label="所属项目" prop="name">
												<el-input :readonly="true"  v-model="ruleForm.plot_name"></el-input>
									  </el-form-item>
									  <el-form-item label="报修来源" prop="name">
										<el-input  :readonly="true"  v-model="ruleForm.is_substitute"></el-input>
									  </el-form-item>
									  <el-form-item label="报修地点" prop="name">
										<el-input  :readonly="true"  v-model="ruleForm.address"></el-input>
									  </el-form-item>
									  <el-form-item label="报修地点-楼" prop="name">
										<el-input  :readonly="true"  v-model="ruleForm.floor"></el-input>
									  </el-form-item>
									  <el-form-item label="报修地点-层" prop="name">
										<el-input  :readonly="true"  v-model="ruleForm.layer"></el-input>
									  </el-form-item>
									  <el-form-item label="报修区域" prop="name">
										<el-input  :readonly="true"  v-model="ruleForm.region"></el-input>
									  </el-form-item>
									  </el-row>
									   <el-row>
								  <el-form-item label="报修分类" prop="name">
								    <el-input :readonly="true"   v-model="ruleForm.father_name"></el-input>
								  </el-form-item>
								  <el-form-item label="报修模式" prop="name">
								    <el-input :readonly="true"  v-model="ruleForm.mode"></el-input>
								  </el-form-item>
								  <el-form-item label="工单类型" prop="name">
								    <el-input :readonly="true"   v-model="ruleForm.order_type" ></el-input>
								  </el-form-item>
								  <el-form-item label="报修金额" prop="name">
								    <el-input  :readonly="true"  v-model="ruleForm.money"></el-input>
								  </el-form-item>
								  </el-row>
								  
								    <el-row>
								  <el-form-item label="详细信息" prop="name">
								    <el-input  :readonly="true"  v-model="ruleForm.content"></el-input>
								  </el-form-item>
								  <el-form-item label="评价星数" prop="name">
								    <el-input :readonly="true"  v-model="ruleForm.stars_nums"></el-input>
								  </el-form-item>
								  <el-form-item label="完成时间" prop="name">
								    <el-input :readonly="true"  v-model="ruleForm.finish_time"></el-input>
								  </el-form-item>
													<el-form-item label="评价内容" prop="name">
													  <el-input :readonly="true"   type="textarea" v-model="ruleForm.comment"></el-input>
													</el-form-item>
								   </el-row>
								  <el-row>
								 <!-- <el-form-item label="图片地址" prop="name">
								    <el-input  :readonly="true" v-model="ruleForm.picture"></el-input>
								  </el-form-item> -->
								  <el-form-item label="维修人员" prop="name">
								    <el-input :readonly="true" v-model="ruleForm.repair_username"></el-input>
								  </el-form-item>
								  <el-form-item label="备注" prop="name">
								    <el-input :readonly="true"   type="textarea" v-model="ruleForm.remark"></el-input>
								  </el-form-item>
								  
								  <el-form-item label="结单说明" prop="name">
								    <el-input :readonly="true"   type="textarea" v-model="ruleForm.reason"></el-input>
								  </el-form-item>
								  	 
								    </el-row>
								 
								   <el-row>
								  <el-form-item label="报修图片" prop="name">
									<el-image
									  border="11"
									  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
									  v-for="item in srcListFirst"
									  v-bind:key="item"
									  hide-on-click-modal="true"
									  :src="item" 
									  :preview-src-list="srcListFirst">
									</el-image>
								  </el-form-item>
								  <el-form-item label="维修图片" prop="name">
									<el-image
									  border="11"
									  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
									  v-for="item in srcList"
									  v-bind:key="item"
									  hide-on-click-modal="true"
									  :src="item" 
									  :preview-src-list="srcList">
									</el-image>
								  </el-form-item>
								 
								  
								  <el-form-item label="电子签名" prop="name" v-show="statementPicture.length > 0">
								  		<el-image
								  		  border="11"
								  		  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
								  		  v-for="item in statementPicture"
								  		  v-bind:key="item"
								  		  hide-on-click-modal="true"
								  		  :src="item" 
								  		  :preview-src-list="statementPicture">
								  		</el-image>
								  </el-form-item>
								   </el-row>
								</el-form>
								
								
							</div>
					   </div>
				  </div>
					       
					
					

				</div>
				<div class="ucCol_15 ucRow_24" >
					
					
					              <div class="ucLay-content" style="z-index: 0;">
					                 <div class="ucCard ucCard-absolute">
					                      <div  style="    padding: 2px 50px 2px 0px;text-align: end;">
					                      											
					                      	<el-button   @click="addition()">新增</el-button>
					                          <el-button   @click="Withdraw()">返回</el-button>
					                          
					                      </div>
					                      <div class="ucCard-content">
											  
											  <el-table :data="dataLists" border  ref="multipleTable">
											    <el-table-column prop="id" label="ID" ></el-table-column>
											    <el-table-column prop="father_name" label="用户名称" ></el-table-column>
											    <el-table-column prop="user_imgs" label="用户头像" >
											      <template width="60" v-slot="colum" style="width:55px" >
											        <el-image style="width:50px;height:50px;border:none; border-radius:70%" :src="colum.row.user_imgs" />
											      </template>
											    </el-table-column>
											    <el-table-column prop="type_name" label="绩效分类"></el-table-column>
											    <el-table-column prop="secn_name" label="绩效名称"></el-table-column>
											    <el-table-column prop="money" label="绩效金额"></el-table-column>
											   <el-table-column prop="multiple" label="倍数"></el-table-column>
											    <el-table-column prop="create_time" label="评定时间"  ></el-table-column>
											    <el-table-column fixed="right" label="操作" width="120px">
											      <template v-slot="scope">
											         <el-button  icon="el-icon-delete"   @click="Deleteinfo(scope.row)" type="danger" size="small">删除</el-button>
											      </template>
											    </el-table-column>
											  </el-table>
										  </div>
					                 </div>
					              </div>
					
				  <el-form ref="searchForm"   label-width="240px" :inline="true">
				     
				  	<!-- <el-form-item ><el-button   @click="TaskDetail()">任务详情</el-button></el-form-item> -->
				  </el-form>
				   
				</div>
		</div>
	</div>
 
	<rep_repairs_view @closerepairsView="closerepairsView"  v-if="repairsViewDialogVisible"></rep_repairs_view>
	 <rms_evaluate_add @closeperformance="closeperformance"  v-if="EvaluateAddDialogVisible"></rms_evaluate_add>
</template>

<script>
import  rms_evaluate_add from './rms_evaluate_add'
import  rep_repairs_view from '../repa/rep_repairs_view'
export default {
  created () {
    this.returnpage();
    this.handleClickQuery();
	this.handleClickQuery2();
  },
  data(){
    return{
		dataLists:[{
		}],
		EvaluateAddDialogVisible:false,
		repairsViewDialogVisible:false,
		order_id:'',
		ruleForm:{
		  id:this.$route.params.father_id,
		},
		srcList:[],
		srcListFirst:[],
		statementPicture:[],
		id : this.$route.params.father_id,
		}
    },
	methods: {
    returnpage:function(){
		  if(this.$route.params.father_id==null||this.$route.params.father_id==''||this.$route.params.father_id==undefined){
			this.Withdraw();
		  }
    },
    handleClickQuery:function(data){
      var params=this.$route.params;
      this.dataLists = [];
      var req = {};
      req.father_id=params.father_id;
      req.cmd = "searchLevelEvaluation";
      this.$sknet.postJson(this.$skconf("brum.surl"), req, function(res) {
        this.dataLists=res.datas.items;
      }, null, this);
    },
    Withdraw:function(){
      this.$router.push({name:'rms_evaluate_list',params:this.$route.params});
    },
	closerepairsView:function(){
	  this.id = this.id;
	  this.repairsViewDialogVisible = false;
	  this.handleClickQuery();
	},
	TaskDetail:function(){
		this.id = this.$route.params.father_id;
		//this.repairsViewDialogVisible = true;
		this.handleClickQuery2();
	},
	handleClickQuery2:function(){
	  this.dataList = [];
	  var req = {};
	  req.cmd = "searchRepairs";
	  req.id=this.id;
	  this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
	    this.ruleForm=res.datas.items[0];
	    var picture=this.ruleForm.picture;
	    var maintain=this.ruleForm.maintain_url;
		
		var statePicture = this.ruleForm.statementPicture;
		if(statePicture != null){
		  var picturearray = statePicture.split(',')
		  for(let i=0;picturearray.length>i;i++){
			  this.statementPicture.push(picturearray[i]);
		  }
		}
		
	    if(picture!=null){
	      var picturearray=picture.split(',')
	      for(let i=0;picturearray.length>i;i++){
			  this.srcListFirst.push(picturearray[i]);
	      }
	    }
	    if(maintain!=null){
	      var maintainarray=maintain.split(',')
	      for(let i=0;maintainarray.length>i;i++){
	        if(maintainarray[i]!=''&&picturearray!=null) {
			  this.srcList.push(maintainarray[i]);
	        }
	      }
	    }
	    
		if(this.ruleForm.mode==1){
		    this.ruleForm.mode='有偿';
		}else if(this.ruleForm.mode==2){
		  this.ruleForm.mode='无偿';
		}
		
		if(this.ruleForm.is_substitute == 1){
		    this.ruleForm.is_substitute='后台录入';
		}else{
		    this.ruleForm.is_substitute='app录入';
		}
		
	    if(this.ruleForm.order_type==1){
	      this.ruleForm.order_type='等待抢单';
	    }else if(this.ruleForm.order_type==2){
	      this.ruleForm.order_type='前台抢单';
	    }else if(this.ruleForm.order_type==3){
	      this.ruleForm.order_type='后台分配';
	    }
	   }, null, this);
	},
    Deleteinfo:function(item){
      this.$confirm('是否确认删除。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
       this.delinfo(item);
      }).catch(() => {});
    },
    delinfo:function(item){
      var self = this;
      var data = {};
      data.cmd = "delLevelEvaluation";
      data.id=item.id,
          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
            self.$message("删除成功");
            this.handleClickQuery();
          }, function(data) {
          }, self);
    },
    addition:function(){
      this.order_id=this.$route.params;
      this.EvaluateAddDialogVisible = true;
    },
    closeperformance:function(){
      this.EvaluateAddDialogVisible = false;
      this.handleClickQuery();
    },
  },
  mounted(){
  },
  components: {
    rms_evaluate_add,rep_repairs_view
  }
}
</script>
<style scoped>
</style>